<template>
  <v-dialog
    class="overflow-visible"
    overlay-color="primary"
    overlay-opacity="0.9"
    v-model="dialog"
    max-width="750"
  >
    <close-button @click="closeDialog()" overflow />

    <v-card v-if="food" class="step-food-dialog" data-step="2">
      <v-card-text class="pt-5">
        <v-form ref="form">
          <v-text-field
            v-model="data.name"
            :rules="[rules.required]"
            label="Name"
            outlined
            dense
          ></v-text-field>

          <v-select
            v-model="data.category"
            :items="categories"
            item-text="name"
            label="Category"
            item-value="id"
            clearable
            outlined
            dense
          ></v-select>

          <v-text-field
            v-if="user.role == 'admin'"
            v-model="data.link"
            label="Link"
            outlined
            dense
          ></v-text-field>

          <v-card class="mb-5" outlined>
            <v-simple-table dense>
              <template #default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Unit</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="value in nValues" :key="value">
                    <template v-if="value !== 'energyCal'">
                      <td width="33%">{{ data[value].name }}</td>
                      <td width="33%">{{ data[value].unit }}</td>
                      <td>
                        <v-sheet width="100%">
                          <v-text-field
                            v-model="data[value].val"
                            hide-details
                            dense
                          ></v-text-field>
                        </v-sheet>
                      </td>
                    </template>
                    <template v-else>
                      <td width="33%">Energy</td>
                      <td width="33%">Cal</td>
                      <td>
                        <v-sheet width="100%">
                          <v-text-field
                            :value="(data['energyKj'].val / 4.18).toFixed(1)"
                            hide-details
                            readonly
                            dense
                          ></v-text-field>
                        </v-sheet>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-form>

        <v-btn
          @click="validateForm()"
          :loading="status.saving"
          color="primary"
          depressed
          >Save</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/rules';
import { mapState, mapActions } from 'vuex';

export default {
  props: {
    food: {
      type: Object,
      required: true,
    },
    user: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      data: {},
      dialog: false,
      nValues: [
        'energyKj',
        'energyCal',
        'protein',
        'fat',
        'carbohydrate',
        'sugars',
        'fibre',
        'sodium',
      ],
    };
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.foods.status,
      categories: (state) => state.foods.categories,
    }),
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    food: {
      handler(value) {
        if (value) {
          this.data = {};
          Object.assign(this.data, value);
        }
      },
      immediate: true,
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('foods', ['saveFood']),

    closeDialog() {
      this.dialog = false;
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveFood(this.data);
      }
    },
  },
};
</script>

<template>
  <v-dialog
    v-model="dialog"
    overlay-color="primary"
    overlay-opacity="0.9"
    max-width="950"
    class="overflow-visible"
  >
    <close-button @click="closeDialog()" overflow />
    <v-card outlined class="pa-5">
        <v-card outlined>
            <v-row>
                <v-col>
                    <v-card-text>
                        <h3 class="primary--text font-weight-bold">{{ food.name }}</h3>
                    </v-card-text>
                </v-col>
                <v-col
                   class="d-flex justify-end align-center mr-4" 
                   style="gap: 0.5rem"
                   v-if="!isEditOn"
                >
                    <v-btn
                        color="primary"
                        depressed
                        small
                        v-if="user.role == 'admin'"
                        @click="toggleEdit"
                    >
                        Edit
                    </v-btn>
                </v-col>
                <v-col
                   class="d-flex justify-end align-center mr-4" 
                   style="gap: 0.5rem"
                   v-else
                >
                    <v-btn
                        color="info"
                        icon
                        small
                        :loading="isUpdating"
                        @click="toggleUpdate()"
                    >
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn
                        color="secondary"
                        icon
                        small
                        @click="cancelEdit"
                    >
                        <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-card-text>
                <v-row>
                    <v-col>
                        <h5 class="primary--text font-weight-bold">Nutrition Tags</h5>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mb-3">
                    <v-col 
                    v-for="(tag, i) in nutritionTags" 
                    :key="i"
                    cols="12"
                    md="6"
                    lg="3"
                    xl="3"
                    >
                        <v-icon color="primary" small v-if="food[tag] === 'Yes'">mdi-check</v-icon>
                        <v-icon color="error" small v-else>mdi-minus-circle-outline</v-icon>
                        {{ tag }}

                        <v-radio-group v-if="isEditOn" v-model="food[tag]" dense row>
                            <v-radio
                                label="Yes"
                                value="Yes"
                            >
                            </v-radio>
                            <v-radio
                                label="No"
                                value="No"
                            >
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <h5 class="primary--text font-weight-bold">Meal Tags</h5>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col 
                    v-for="(tag, i) in mealTags" 
                    :key="i"
                    cols="12"
                    md="6"
                    lg="3"
                    xl="3"
                    >
                        <v-icon color="primary" small v-if="food[tag] === 'Yes'">mdi-check</v-icon>
                        <v-icon color="error" small v-else>mdi-minus-circle-outline</v-icon>
                        {{ tag }}

                        <v-radio-group v-if="isEditOn" v-model="food[tag]" dense row>
                            <v-radio
                                label="Yes"
                                value="Yes"
                            >
                            </v-radio>
                            <v-radio
                                label="No"
                                value="No"
                            >
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <h5 class="primary--text font-weight-bold">Others</h5>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col 
                    v-for="(other, i) in others" 
                    :key="i"
                    cols="12"
                    md="6"
                    lg="3"
                    xl="3"
                    >
                        <span class="primary--text" small v-if="food[other]">{{ food[other] }}</span>
                        <v-icon color="error" small v-else>mdi-minus-circle-outline</v-icon>
                        {{ other }}
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-card>
 </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    name: 'FoodTag',
    props: {
        food: {
            type: Object,
            dialog: false,
            required: true,
        },
        user: {
            type: Object
        }
    },
    data () {
        return {
            dialog: false,
            isEditOn: false,
            originalFood: {}
        }
    },
    computed: {
        ...mapState({
            nutritionTags: state => state.excel.nutritionTags,
            mealTags: state => state.excel.mealTags,
            others: state => state.excel.others,
            isUpdating: state => state.excel.status.updating
        })
    },
    methods: {
        ...mapActions('excel', ['updateAFood']),
        closeDialog() {
            this.dialog = false
        },
        toggleEdit () {
            this.originalFood = {...this.food}
            this.isEditOn = true;
        },
        cancelEdit () {
            this.food = {...this.originalFood}
            this.isEditOn = false;
        },
        toggleUpdate() {
            this.updateAFood(this.food)
            .finally(() => {
                this.isEditOn = false;
            })
        }
    },
}
</script>

<style>

</style>